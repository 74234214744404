import styled from 'styled-components';
import GridMUI from '@mui/material/Grid';
import TextFieldMUI from '@mui/material/TextField';

interface GridProps {
    padding?: string;
}

export const Grid = styled(GridMUI)<GridProps>`
    padding: ${(props) => props.padding};
`;

interface TextFieldProps {
	font?: string
}

export const TextField = styled(TextFieldMUI)<TextFieldProps>`
    div {
        font-size: ${(props) => props.font};

        background-color: #ffffff;
        border-radius: 4px;
        min-height: 150px;
        align-items: start;
    }
    .space {
        padding-left: 10px;
    }
`;

export const RequiredSpan = styled.span`
    color: red;
`;
