import { useContext } from 'react';
import { Context } from './store';
import { baseColor } from '@/utils';
import { appBarState } from './reducer';

export default function useStore() {
    const [storeState, dispatch] = useContext(Context);

    const setDefault = (actionType: string) => (val: unknown) => dispatch({ type: actionType, payload: val });

    const resetReducer = () => {
        const icon = localStorage.getItem('icon');
        const initialState: appBarState = {
            primaryColor: localStorage.getItem('primaryColor') ?? '',

            icon: icon ?? '/grupoPanamaH.png',
            backgroundImg: localStorage.getItem('backgroundImg') ?? '',
            cDescripcion: localStorage.getItem('cDescripcion') ?? '',

            userInfo: localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo') ?? '{}') : {},
            loading: false,
            surveytoken: localStorage.getItem('surveytoken'),

            oUser: localStorage.getItem('oUser') ? JSON.parse(localStorage.getItem('oUser') ?? '{}') : undefined,
            bIsLogged: localStorage.getItem('bIsLogged') === 'true',
            cSessionToken: localStorage.getItem('SSO_CREDENTIALS') ?? '',
            back: '/',
        };

        dispatch({
            type: 'RESET',
            initial_state: initialState,
        });
    };

    const setBackButton = (val: string | false) => {
        dispatch({
            type: 'UPDATE_BACK',
            payload: val,
        });
    };

    const setUserInfo = (val: unknown) => {
        dispatch({
            type: 'UPDATE_USER_INFO',
            payload: val,
        });
    };

    const setPrimaryColor = (val: unknown) => {
        dispatch({
            type: 'UPDATE_COLOR',
            payload: val,
        });
    };

    const setAssets = (primaryColorVal: string, iconVal: string, backgroundImgVal: string, cDescripcionVal: string) => {
        dispatch({
            type: 'UPDATE_ASSETS',
            payload: {
                primaryColor: primaryColorVal,
                icon: iconVal,
                backgroundImg: backgroundImgVal,
                cDescripcion: cDescripcionVal,
            },
        });
    };

    const setSurveyToken = (val: unknown) => {
        dispatch({
            type: 'UPDATE_SURVEY_TOKEN',
            payload: val,
        });
    };
	
    const removeAssets = () => {
        localStorage.removeItem('icon');
        localStorage.removeItem('backgroundImg');
        localStorage.removeItem('cDescripcion');
        localStorage.removeItem('primaryColor');
        resetReducer();
    };

    //AUTENTICACIÓN
    const setUser = setDefault('UPDATE_USER');
    const setIsLogged = setDefault('UPDATE_IS_LOGGED');
    const setSessionToken = setDefault('UPDATE_SESSION_TOKEN');

    const userInfo = storeState?.userInfo;
    const primaryColor = storeState?.primaryColor ?? baseColor;

    const backgroundImg = storeState?.backgroundImg ?? false;

    const cDescripcion = storeState?.cDescripcion ?? false;

    const icon = storeState?.icon ?? false;
    const surveytoken = storeState?.surveytoken;
    const back = storeState?.back;

    const oUser = storeState?.oUser ?? false;
    const bIsLogged = storeState?.bIsLogged ?? false;
    const cSessionToken = storeState?.cSessionToken ?? '';

    return {
        resetReducer,
        storeState,
        userInfo,
        setPrimaryColor,
        setUserInfo,
        primaryColor,

        backgroundImg,
        cDescripcion,
        icon,
        removeAssets,
        setAssets,
        setSurveyToken,
        surveytoken,
        back,
        setBackButton,

        oUser,
        bIsLogged,
        cSessionToken,

        setUser,
        setIsLogged,
        setSessionToken,
    };
}
