import { appBarState } from '@/store/main/reducer';
import { handleRedirectSSO } from '@/utils/Auth';
import { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

const useLogout = (resetReducer: () => void, storeState: appBarState) => {
    const location = useLocation();
    const navigate = useNavigate();

    const { encuestaId } = useParams();

    const handleLogout = () => {
        localStorage.removeItem('userInfo');
        localStorage.removeItem('primaryColor');
        localStorage.removeItem('surveytoken');
        resetReducer();
        navigate(`/`);
        handleRedirectSSO();
    };

    useEffect(() => {
        const userInfoCookies = localStorage.getItem('userInfo');
        if (!userInfoCookies && location.pathname.includes('encuestas')) {
            navigate(`/`);
        }

        const userInfo = userInfoCookies && JSON.parse(userInfoCookies);
        const includeAdmin = location.pathname.includes('/admin');

        if (includeAdmin && userInfo && userInfo.nTipoUsuario !== 1) {
            handleLogout();
        }

		if (!includeAdmin && userInfo && userInfo.nTipoUsuario === 1) {
            navigate(`/admin`);
        }
    }, []);

    return {
        handleLogout,
        storeState,
        encuestaId,
    };
};

export default useLogout;
