import styled, { keyframes } from 'styled-components';
import GridMUI from '@mui/material/Grid';
import ButtonMUI from '@mui/material/Button';
import ArrowBackIconMUI from '@mui/icons-material/ArrowBack';

interface ArrowBackIconProps {
    blackicon: 'true' | string;
}

export const ArrowBackIcon = styled(ArrowBackIconMUI)<ArrowBackIconProps>`
    color: ${(props) => (props.blackicon === 'true' ? '#000000' : '#ffffff')};
`;
const colorFade = keyframes`
  from {
    background-color: #f5f5f5;
  }
  to {
    background-color: #E5E5E5;

  }
`;

interface ButtonProps {
    istouchdevice?: 'true' | string;
}

export const Button = styled(ButtonMUI)<ButtonProps>`
    ${(props) => props.istouchdevice === 'true' ? `
        color: #ffffff;
        p {
            font-size: 24px !important;
        }
        box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
		@media (max-width: 530px) {
			p {
				font-size: 18px !important;
			}
		}
    ` : `
		background-color: #f5f5f5;
		color: #000000;
		p {
			font-size: 14px !important;
		}
		box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
    `}

    ${ArrowBackIcon} {
        font-size: ${(props) => (props.istouchdevice === 'true' ? '30px' : '20px')};
    }

    &:hover {
        cursor: pointer;
        animation-name: ${colorFade};
        animation-duration: 0.2s;
        animation-fill-mode: forwards;
    }
`;

interface GridProps {
	padding?: string
	minheight?: string
	bgcolor?: string
}

export const Grid = styled(GridMUI)<GridProps>`
    padding: ${(props) => props.padding};
    min-height: ${(props) => props.minheight};
    background-color: ${(props) => props.bgcolor};
`;
