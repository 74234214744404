import styled from "styled-components";
import DialogActionsMUI from '@mui/material/DialogActions';
import DialogContentMUI from '@mui/material/DialogContent';

export const DialogActions = styled(DialogActionsMUI)`
    padding: 0 30px 30px 30px;
`;
export const DialogContent = styled(DialogContentMUI)`
    padding: 30px;
`;
