import { SSO_API_URL, SSO_SITE_URL } from "@/constants";

interface withFetchParams {
	url: RequestInfo
	headers?: RequestInit['headers']
	method: RequestInit['method']
	mode?: RequestInit['mode']
	credentials?: RequestInit['credentials']
}

interface fetchError {
    Success: false;
    Data: string[];
}

interface fetchSuccess<Type> {
    Success: true;
    Data: Type;
}

interface fetchThen<Type> {
	status: number
	headers: Headers
	response: fetchError | fetchSuccess<Type>
}

interface fetchCatch {
	error: unknown,
	response: fetchError
}

export const customFetch =
	<T = unknown>({ url, headers, method, mode, credentials }: withFetchParams) =>
	(dataParams?: string | Record<string, string>): Promise<fetchThen<T> | fetchCatch> => {
		const body: Record<string, string> = method == 'POST' ? { body: JSON.stringify(dataParams) } : {};

		url = method === 'GET' && typeof dataParams !== 'string' ? `${url}?${new URLSearchParams(dataParams).toString()}` : typeof dataParams === 'string' ? `${url}/${dataParams}` : url;

		return fetch(url, {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				'Access-Control-Allow-Credentials': 'true',
				...headers,
			},
			credentials: credentials ?? 'include',
			mode: mode ?? 'cors',
			method,
			...body,
		})
			.then(async (response) => {
				return {
					status: response.status,
					headers: response.headers,
					response: await response.json(),
				} as fetchThen<T>
			})
			.catch((error) => {
				return {
					error,
					response: {
						Success: false,
						Data: [String(error).split(': ')[1] ?? String(error)],
					}
				} as fetchCatch;
			});
	};

export const validarOTP = customFetch<string>({ url: `${SSO_API_URL}/Valida/OTP`, method: 'POST' });
export const validarSesion = customFetch({ url: `${SSO_API_URL}/Valida/Sesion`, method: 'POST' });

// export const handleRedirectSSO = () => process.env.NODE_ENV != 'development' && (window.location.href = SSO_SITE_URL ?? '/');
export const handleRedirectSSO = () => {
	localStorage.removeItem('SSO_CREDENTIALS');
	window.location.href = SSO_SITE_URL ?? '/';
};