import { handleRedirectSSO } from "./Auth";

interface fetchError {
    success: false;
    data: string[];
}

interface fetchSuccess<Type> {
    success: true;
    data: Type;
}

interface simpleFetchParams {
    url: string;
    headers?: RequestInit['headers'];
    method: RequestInit['method'];
    mode?: RequestInit['mode'];
    credentials?: RequestInit['credentials'];
    params?: string | Record<string, unknown>;
}

export const simpleFetch = <Type>({
    url,
    headers,
    method,
    mode,
    credentials,
    params,
}: simpleFetchParams): Promise<fetchSuccess<Type> | fetchError> => {
    const body: Record<string, string> = method == 'POST' ? { body: JSON.stringify(params) } : {};

    url = method === 'GET' && typeof params === 'object' ? `${url}?${new URLSearchParams(params as Record<string, string>).toString()}` : typeof params === 'string' ? `${url}/${params}` : url;

    return fetch(url, {
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            'Access-Control-Allow-Credentials': 'true',
            Authorization: localStorage.getItem('SSO_CREDENTIALS') ?? '""',
            ...headers,
        },
        credentials: credentials ?? 'include',
        mode: mode ?? 'cors',
        method,
        ...body,
    })
        .then((response) => {
            if (response.status === 210 && !!response.headers.get('RefreshedToken')) {
                localStorage.setItem('SSO_CREDENTIALS', response.headers.get('RefreshedToken') ?? '');
            }

            return response.json();
        })
        .then((response) => {
            if (response?.reason === 'AuthenticationError') {
                handleRedirectSSO();
            }

            return response?.Success ? {
                data: response.Data as Type,
                success: true as const,
            } : {
                data: response.Error as string[],
                success: false as const,
            }
        })
        .catch((error) => {
            if (error instanceof Error) {
                return {
                    success: false,
                    data: [error.message],
                };
            }
            return {
                success: false,
                data: [String(error)],
            };
        });
};
