import styled from 'styled-components';
import GridMUI from '@mui/material/Grid';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ButtonMUI from '@mui/material/Button';
import CheckboxMUI from '@mui/material/Checkbox';
import TextFieldMUI from '@mui/material/TextField';
import TypographyMUI from '@mui/material/Typography';
import AutocompleteMUI from '@mui/material/Autocomplete';

export const Autocomplete = styled(AutocompleteMUI)`
    width: 100%;
`;

interface ButtonProps {
    width?: string;
    margin?: string;
    bgcolor?: string;
}

export const Button = styled(ButtonMUI)<ButtonProps>`
    width: ${(props) => props.width};
    margin: ${(props) => props.margin};
    background-color: ${(props) => props.bgcolor};
`;

interface TextFieldProps {
    width?: string;
}

export const TextField = styled(TextFieldMUI)<TextFieldProps>`
    width: ${(props) => props.width};
`;

interface TypographyProps {
    font?: string;
}

export const Typography = styled(TypographyMUI)<TypographyProps>`
    font-size: ${(props) => props.font};
`;

export const Checkbox = styled(CheckboxMUI)`
    padding: 0px;
    height: 20px;
    width: 20px;
`;

interface TableItemProps {
    borderbottom?: 'true' | string;
    borderright?: 'true' | string;
}

export const TableItem = styled(GridMUI)<TableItemProps>`
    padding: 10px 15px 10px 15px;
    ${(props) =>
        props.borderbottom === 'true'
            ? `
		border-bottom: 1px solid #ededed;
	`
            : null}
    ${(props) =>
        props.borderright === 'true'
            ? `
		border-right: 1px solid #ededed;
	`
            : null}
`;
export const Table = styled(GridMUI)`
    border-radius: 5px;
    border: 1px solid #ededed;
`;

interface GridProps {
    padding?: string;
}

export const Grid = styled(GridMUI)<GridProps>`
    padding: ${(props) => props.padding};
`;
export const UsuarioLayout = styled.div`
    padding: 10px 10% 10% 10%;
`;

interface ExpandModeProps {
    iconup?: 'true' | string;
}

export const ExpandMore = styled(ExpandMoreIcon)<ExpandModeProps>`
    ${(props) =>
        props.iconup === 'true' ? `
			transform: rotate(180deg);
			transition: 0.2s ease all;
		` : `
			transform: rotate(0);
			transition: 0.2s ease all;
		`
	}
`;

export const CardContainer = styled(GridMUI)`
    border-radius: 8px;
    background-color: #ffffff;
    padding: 10px 20px 10px 20px;
    margin-top: 20px;
`;

export const Header = styled(GridMUI)`
    padding: 15px 0 7px 0;
    border-bottom: 2px solid #ededed;
    margin-bottom: 10px;
`;

export const TableContainer = styled(GridMUI)`
    box-shadow:
        rgba(50, 50, 105, 0.15) 0px 2px 5px 0px,
        rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
`;
