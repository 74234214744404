import { Button } from '@mui/material';
import {DoneContainer, ErrorIcon, Typography} from './styled';

interface props {
    subtitle: string,
    handleClick?: React.MouseEventHandler<HTMLButtonElement>,
    textButton?: string
}

const ErrorMessageComponent = ({subtitle, textButton, handleClick}: props) => {
    return <DoneContainer direction="column" container justifyContent="center" alignItems="center">
        <ErrorIcon />
        <Typography padding="15px 0 15px 0" variant='subtitle1' align='center'>Error.</Typography>
        <Typography padding="0 0 15px 0" color='#757575' variant='subtitle2' align='center'>{subtitle}</Typography>
        <Button onClick={handleClick} color='primary' variant='contained'>
            <Typography color="#fff">{textButton ?? 'INICIO'}</Typography>
        </Button>
    </DoneContainer>;
};

export default (ErrorMessageComponent);
