import { Grid } from '@mui/material';
import { CardContainer, Typography, ArrowFoward, EmptyCard } from './styled';
import { Encuesta } from '@/hooks/useMain';

interface props {
	isTouchDevice: boolean
	item: Encuesta
	handleClick: (item: Encuesta) => void
}

const SurveyCardComponent = ({ item, handleClick, isTouchDevice } : props) => {
    if (item) {
        return (
            <CardContainer
                onClick={() => handleClick(item)}
                justifyContent="space-between"
                alignItems="center"
                container
            >
                <Grid item xs>
                    <Typography variant={isTouchDevice ? 'h6' : 'subtitle1'}>{item.cDescripcion}</Typography>
                </Grid>
                <ArrowFoward />
            </CardContainer>
        );
    } else {
        return <EmptyCard />;
    }
};

export default SurveyCardComponent;
