import styled, { keyframes } from 'styled-components';
import GridMUI from '@mui/material/Grid';
import TextFieldMUI from '@mui/material/TextField';
import ButtonMUI from '@mui/material/Button';

interface TextFieldProps {
    font?: string;
}

export const TextField = styled(TextFieldMUI)<TextFieldProps>`
    input {
        font-size: ${(props) => props.font};
    }
`;

const opacityFade = keyframes`
	from {
		background-color: #597e6e;
	}
	to {
		background-color: #678778;
	}
`;

interface ButtonProps {
	istouchdevice?: 'true' | string
	disabledcolor?: 'true' | string
}

export const Button = styled(ButtonMUI)<ButtonProps>`
    ${(props) => props.istouchdevice === 'true' ? `
		opacity: 1;
		background-color: ${props.disabledcolor === 'true' ? '#E0E0E0' : '#597e6e'};
		border-color: ${props.disabledcolor === 'true' ? null : '#597e6e'};
		font-size: 20px;
		box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
		color: #ffffff;
    ` : `
		opacity: 1;
		background-color: ${props.disabledcolor === 'true' ? '#E0E0E0' : '#597e6e'};
		border-color: ${props.disabledcolor === 'true' ? null : '#597e6e'};
		p {
			font-size: 14px;
		}
		box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
		color: #ffffff;
    `};
    &:hover {
        cursor: pointer;
        animation-name: ${opacityFade};
        animation-duration: 0.4s;
        animation-fill-mode: forwards;
    }
`;

interface GridProps {
	padding ?: string
	minheight ?: string
	bgcolor ?: string
}

export const Grid = styled(GridMUI)<GridProps>`
    padding: ${(props) => props.padding};
    min-height: ${(props) => props.minheight};
    background-color: ${(props) => props.bgcolor};
`;
