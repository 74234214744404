import styled, { keyframes, css } from 'styled-components';
import GridMUI from '@mui/material/Grid';
import ButtonMUI from '@mui/material/Button';
import TypographyMUI from '@mui/material/Typography';
import NextIconMUI from '@mui/icons-material/ArrowForwardIos';
import BackIconMUI from '@mui/icons-material/ArrowBackIosNew';
import CheckCircleIconMUI from '@mui/icons-material/CheckCircle';
import { getColorsByGrade } from '@/utils';

interface Typography {
    font?: string;
}

export const Typography = styled(TypographyMUI)<Typography>`
    font-size: ${(props) => props.font};
`;

interface IconProps {
    font?: string;
    disabledicon?: string;
    primarycolor?: string;
}

export const CheckCircleIcon = styled(CheckCircleIconMUI)<IconProps>`
    font-size: 30px;
    color: ${(props) => (props.disabledicon === 'true' ? props.primarycolor : '#ffffff')};
`;

export const NextIcon = styled(NextIconMUI)<IconProps>`
    color: ${(props) => (props.disabledicon === 'true' ? props.primarycolor : '#ffffff')};
`;

export const BackIcon = styled(BackIconMUI)<IconProps>`
    color: ${(props) => (props.disabledicon === 'true' ? props.primarycolor : '#ffffff')};
`;

const colorFade = keyframes`
    from {
        background-color: #f5f5f5;
    }
    to {
        background-color: #E5E5E5;
    }
`;

interface ButtonProps {
    istouchdevice?: string;
    back?: string;
    next?: string;
}

export const Button = styled(ButtonMUI)<ButtonProps>`
    ${(props) =>
        props.istouchdevice === 'true'
            ? `
        background-color: transparent !important;
        color: #ffffff;
        p {
            font-size: 24px !important;
        }
    `
            : `
    background-color: #f5f5f5;
    color: #000000;
    p {
        font-size: 14px !important;

    }
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
    
    `};
    ${NextIcon} {
        font-size: 40px !important;
    }
    ${BackIcon} {
        font-size: 40px !important;
    }
    ${CheckCircleIcon} {
        font-size: 50px !important;
    }
    &:hover {
        cursor: pointer;
        animation-name: ${colorFade};
        animation-duration: 0.2s;
        animation-fill-mode: forwards;
    }
    ${NextIcon} {
        transform: ${(props) => (props.next === 'true' ? 'translateX(5px)' : 'translateX(0px)')};
        transition: ${(props) => (props.next === 'true' ? '1s ease all' : '')};
    }
    ${BackIcon} {
        transform: ${(props) => (props.back === 'true' ? 'translateX(5px)' : 'translateX(0px)')};
        transition: ${(props) => (props.back === 'true' ? '1s ease all' : '')};
    }
`;

export const SurveyLayoutContainer = styled(GridMUI)`
    overflow: hidden;
`;

interface GridProps {
    padding?: string;
    minheight?: string;
    bgcolor?: string;
}

export const Grid = styled(GridMUI)<GridProps>`
    padding: ${(props) => props.padding};
    min-height: ${(props) => props.minheight};
    background-color: ${(props) => props.bgcolor};
`;

export const BottomBarContainer = styled(GridMUI)<{ istouchdevice?: string }>`
    ${(props) =>
        props.istouchdevice === 'true'
            ? `
        padding: 12px 32px 12px 32px;
        width: 100%;
        background-color: ${props.bgcolor};
        z-index: 1;
        height: 19%;
        @media (max-width: 700px) {
            padding: 12px 20px 12px 20px;
            height: 15%;
        }
    `
            : `
        padding: 12px 0 12px 0;
    `};
`;

interface RaitingNumberProps {
    isTouch?: boolean;
    active?: string;
    grade?: number;
}

export const RaitingNumber = styled.div<RaitingNumberProps>`
    padding: 2.5px;
    .button {
        cursor: pointer;
        height: ${(props) => (props.isTouch ? '70px' : '48px')};
        width: ${(props) => (props.isTouch ? '70px' : '48px')};
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 3px;
        transition: all 0.2s ease-in-out;
        p {
            font-size: 25px !important;
        }
        ${(props) => props.active === 'true' 
            ? css`
                background-color: ${getColorsByGrade(props.grade ?? 0)};
                color: white !important;
            ` : css`
                background-color: rgb(224, 224, 224);
                color: rgb(137, 137, 137) !important;
            `
        };

        @media (max-width: 700px) {
            height: 48px;
            width: 48px;
            p {
                font-size: 16px !important;
            }
        }
    }
`;
