import styled from 'styled-components';
import GridMUI from '@mui/material/Grid';
import TextFieldMUI from '@mui/material/TextField';
import LockIconMUI from '@mui/icons-material/Lock';

interface GridProps {
	padding?: string
}

export const Grid = styled(GridMUI)<GridProps>`
    padding: ${(props) => props.padding};
`;
export const TextField = styled(TextFieldMUI)`
    width: 40px;
    div {
        background-color: #ffffff;
    }
    input {
        -webkit-text-security: disc;
    }
    input[type='number'] {
        -moz-appearance: textfield;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }
`;

export const LockIcon = styled(LockIconMUI)`
    color: #b5b5b5;
    font-size: 120px;
`;
