import { FormControlLabel } from '@mui/material';
import { Radio, RadioGroup } from './styled';

interface Answer {
    nRespuesta: number;
    cDescripcion: string;
}

interface props {
    answers: Answer[];
    setValue: (value: string) => void;
    value: string;
}

const RadioGroupField = ({ value, answers, setValue }: props) => {
    return (
        <RadioGroup row value={value} onChange={(event) => setValue(event.target.value)}>
            {answers.map((answer, index) => (
                <FormControlLabel
                    key={index}
                    value={answer.nRespuesta}
                    control={<Radio />}
                    label={answer.cDescripcion}
                />
            ))}
        </RadioGroup>
    );
};

export default RadioGroupField;
