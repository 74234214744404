import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { Typography } from '../SuccessMessage/styled';
import { Grid } from '@mui/material';
import { DialogActions, DialogContent } from './styled';
import { useStoreState } from 'hooks';

interface props {
	open: boolean,
    handleCancelar: () => void,
    handleAceptar: () => void,
    title: string,
    subtitle: string,
}

const ExitDialogComponent = ({ open, handleCancelar, handleAceptar, title, subtitle }: props) => {
    const { primaryColor } = useStoreState();

    return (
		<Dialog
			open={open}
			onClose={handleCancelar}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogContent>
				<Grid item container direction="column" justifyContent="center" alignContent="center">
					<Grid container>
						<Typography padding="15px 0 15px 0" variant="h6" align="center">
							<strong>{title}</strong>
						</Typography>
					</Grid>
					<Typography padding="0 0 15px 0" color="#757575" variant="subtitle1" align="center">
						{subtitle}
					</Typography>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleCancelar}>
					<Typography color={primaryColor}>Cancelar</Typography>
				</Button>
				<Button
					variant="contained"
					color="primary"
					onClick={() => {
						handleAceptar();
						handleCancelar();
					}}
					// eslint-disable-next-line jsx-a11y/no-autofocus
					autoFocus
				>
					<Typography variant="subtitle2" color="#FFFFFF">
						Aceptar
					</Typography>
				</Button>
			</DialogActions>
		</Dialog>
    );
};

export default ExitDialogComponent;
