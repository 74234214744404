import MainView from '@/views/main/index';
import { useLogin } from '@/hooks';

export const Component = () => {
    const {
        storeState,
        primaryColor,
        resetReducer,
    } = useLogin();

    /*if(storeState && storeState.loading) return <Loading></Loading>;

	if(storeState && !storeState.userInfo.nUsuario) return 
	<LoginView
		urlLocal={urlLocal}
		loading={loading}
		user={user}
		setuser={setuser}
		status={status}
		password={password}
		setpassword={setpassword}
		handleLogin={handleLogin}
	/>*/
    return (
        <MainView
            resetReducer={resetReducer}
            storeState={storeState}
            primaryColor={primaryColor}
        />
    );
}

export { Component as MainViewPage }
