import { useEffect } from "react";

import { handleRedirectSSO, validarOTP } from "@/utils/Auth";

import {Loading} from 'components';

import useStore from '@/store/main/action';

import { useNavigate, useParams } from "react-router-dom";

export const Component = () => {
	//const navigate = useNavigate();

	/*const [DeviceToken, OTP] = router.query.OTP.split('-');

	console.log(DeviceToken)
	console.log(OTP)*/

	//console.log(router.query.OTP.split('-'));

	/*const context = useContext(AuthContext);
	console.log(context);
	//const { auth_token = '' } = useParams();
	console.log(router?.query);

	useEffect(() => {
		console.log(router.query);

		!bIsLogged && handleValidaOTP(OTP || '', DeviceToken || '').then((bValid) => {
			if (!bValid) handleRedirectSSO();
			else router.push('/');
		}) || router.push('/');
	}, [OTP]);

	return (<Loading />)*/

	//console.log(otp?.split('-'))

	const { otp } = useParams();
	const navigate = useNavigate();

	const { bIsLogged, setSessionToken } = useStore();

	const handleValidaOTP = async (cOTP: string, cDeviceToken: string) => {
		const { response } = await validarOTP({ cOTP, cDeviceToken });
	
		response.Success && setSessionToken(response.Data);

		return response.Success;
	}

	useEffect(()=>{
		if (!otp || otp == 'undefined') {
			handleRedirectSSO();
		};

		const [DeviceToken, OTP] = otp?.split('-') ?? [];

		!bIsLogged && handleValidaOTP(OTP || '', DeviceToken || '')
			.then((bValid) => !bValid && handleRedirectSSO() || navigate('/'))
			.catch(error => console.log(error)) /*|| router.push('/');*/

	}, [otp]);

	return (<Loading />);
}

export { Component as ValidarOTP }