interface IconProps {
    width: string;
    height: string;
    fill: string;
}

export const IconEmoticonSad = ({ width, height, fill }: IconProps) => {
    return (
        <svg
            fill={fill}
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            id="mdi-emoticon-sad"
            width={width}
            height={height}
            viewBox="0 0 24 24"
        >
            <path d="M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M7,9.5C7,8.7 7.7,8 8.5,8C9.3,8 10,8.7 10,9.5C10,10.3 9.3,11 8.5,11C7.7,11 7,10.3 7,9.5M14.77,17.23C14.32,16.5 13.25,16 12,16C10.75,16 9.68,16.5 9.23,17.23L7.81,15.81C8.71,14.72 10.25,14 12,14C13.75,14 15.29,14.72 16.19,15.81L14.77,17.23M15.5,11C14.7,11 14,10.3 14,9.5C14,8.7 14.7,8 15.5,8C16.3,8 17,8.7 17,9.5C17,10.3 16.3,11 15.5,11Z" />
        </svg>
    );
};

export const IconEmoticonSadOutline = ({ width, height, fill }: IconProps) => {
    return (
        <svg
            fill={fill}
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            id="mdi-emoticon-sad-outline"
            width={width}
            height={height}
            viewBox="0 0 24 24"
        >
            <path d="M20,12A8,8 0 0,0 12,4A8,8 0 0,0 4,12A8,8 0 0,0 12,20A8,8 0 0,0 20,12M22,12A10,10 0 0,1 12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2A10,10 0 0,1 22,12M15.5,8C16.3,8 17,8.7 17,9.5C17,10.3 16.3,11 15.5,11C14.7,11 14,10.3 14,9.5C14,8.7 14.7,8 15.5,8M10,9.5C10,10.3 9.3,11 8.5,11C7.7,11 7,10.3 7,9.5C7,8.7 7.7,8 8.5,8C9.3,8 10,8.7 10,9.5M12,14C13.75,14 15.29,14.72 16.19,15.81L14.77,17.23C14.32,16.5 13.25,16 12,16C10.75,16 9.68,16.5 9.23,17.23L7.81,15.81C8.71,14.72 10.25,14 12,14Z" />
        </svg>
    );
};
export const IconEmoticonConfusedOutline = ({ width, height, fill }: IconProps) => {
    return (
        <svg
            fill={fill}
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            id="mdi-emoticon-confused-outline"
            width={width}
            height={height}
            viewBox="0 0 24 24"
        >
            <path d="M20 12A8 8 0 1 0 12 20A8 8 0 0 0 20 12M22 12A10 10 0 1 1 12 2A10 10 0 0 1 22 12M15.5 8A1.5 1.5 0 1 1 14 9.5A1.54 1.54 0 0 1 15.5 8M10 9.5A1.5 1.5 0 1 1 8.5 8A1.54 1.54 0 0 1 10 9.5M17 15H13A4 4 0 0 0 9.53 17L7.8 16A6 6 0 0 1 13 13H17Z" />
        </svg>
    );
};
export const IconEmoticonConfused = ({ width, height, fill }: IconProps) => {
    return (
        <svg
            fill={fill}
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            id="mdi-emoticon-confused"
            width={width}
            height={height}
            viewBox="0 0 24 24"
        >
            <path d="M12 2A10 10 0 1 0 22 12A10 10 0 0 0 12 2M8.5 8A1.5 1.5 0 1 1 7 9.5A1.54 1.54 0 0 1 8.5 8M17 16H13A4 4 0 0 0 9.53 18L7.8 17A6 6 0 0 1 13 14H17M15.5 11A1.5 1.5 0 1 1 17 9.5A1.54 1.54 0 0 1 15.5 11Z" />
        </svg>
    );
};
export const IconEmoticonNeutralOutline = ({ width, height, fill }: IconProps) => {
    return (
        <svg
            fill={fill}
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            id="mdi-emoticon-neutral-outline"
            width={width}
            height={height}
            viewBox="0 0 24 24"
        >
            <path d="M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4A8,8 0 0,1 20,12A8,8 0 0,1 12,20M8.5,11A1.5,1.5 0 0,1 7,9.5A1.5,1.5 0 0,1 8.5,8A1.5,1.5 0 0,1 10,9.5A1.5,1.5 0 0,1 8.5,11M17,9.5A1.5,1.5 0 0,1 15.5,11A1.5,1.5 0 0,1 14,9.5A1.5,1.5 0 0,1 15.5,8A1.5,1.5 0 0,1 17,9.5M16,14V16H8V14H16Z" />
        </svg>
    );
};
export const IconEmoticonNeutral = ({ width, height, fill }: IconProps) => {
    return (
        <svg
            fill={fill}
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            id="mdi-emoticon-neutral"
            width={width}
            height={height}
            viewBox="0 0 24 24"
        >
            <path d="M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M7,9.5A1.5,1.5 0 0,1 8.5,8A1.5,1.5 0 0,1 10,9.5A1.5,1.5 0 0,1 8.5,11A1.5,1.5 0 0,1 7,9.5M16,16H8V14H16V16M15.5,11A1.5,1.5 0 0,1 14,9.5A1.5,1.5 0 0,1 15.5,8A1.5,1.5 0 0,1 17,9.5A1.5,1.5 0 0,1 15.5,11Z" />
        </svg>
    );
};
export const IconEmoticonHappyOutline = ({ width, height, fill }: IconProps) => {
    return (
        <svg
            fill={fill}
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            id="mdi-emoticon-happy-outline"
            width={width}
            height={height}
            viewBox="0 0 24 24"
        >
            <path d="M20,12A8,8 0 0,0 12,4A8,8 0 0,0 4,12A8,8 0 0,0 12,20A8,8 0 0,0 20,12M22,12A10,10 0 0,1 12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2A10,10 0 0,1 22,12M10,9.5C10,10.3 9.3,11 8.5,11C7.7,11 7,10.3 7,9.5C7,8.7 7.7,8 8.5,8C9.3,8 10,8.7 10,9.5M17,9.5C17,10.3 16.3,11 15.5,11C14.7,11 14,10.3 14,9.5C14,8.7 14.7,8 15.5,8C16.3,8 17,8.7 17,9.5M12,17.23C10.25,17.23 8.71,16.5 7.81,15.42L9.23,14C9.68,14.72 10.75,15.23 12,15.23C13.25,15.23 14.32,14.72 14.77,14L16.19,15.42C15.29,16.5 13.75,17.23 12,17.23Z" />
        </svg>
    );
};
export const IconEmoticonHappy = ({ width, height, fill }: IconProps) => {
    return (
        <svg
            fill={fill}
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            id="mdi-emoticon-happy"
            width={width}
            height={height}
            viewBox="0 0 24 24"
        >
            <path d="M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M7,9.5C7,8.7 7.7,8 8.5,8C9.3,8 10,8.7 10,9.5C10,10.3 9.3,11 8.5,11C7.7,11 7,10.3 7,9.5M12,17.23C10.25,17.23 8.71,16.5 7.81,15.42L9.23,14C9.68,14.72 10.75,15.23 12,15.23C13.25,15.23 14.32,14.72 14.77,14L16.19,15.42C15.29,16.5 13.75,17.23 12,17.23M15.5,11C14.7,11 14,10.3 14,9.5C14,8.7 14.7,8 15.5,8C16.3,8 17,8.7 17,9.5C17,10.3 16.3,11 15.5,11Z" />
        </svg>
    );
};
export const IconEmoticonExitedOutline = ({ width, height, fill }: IconProps) => {
    return (
        <svg
            fill={fill}
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            id="mdi-emoticon-excited-outline"
            width={width}
            height={height}
            viewBox="0 0 24 24"
        >
            <path d="M12,2C6.47,2 2,6.47 2,12C2,17.53 6.47,22 12,22A10,10 0 0,0 22,12C22,6.47 17.5,2 12,2M12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4A8,8 0 0,1 20,12A8,8 0 0,1 12,20M13,9.94L14.06,11L15.12,9.94L16.18,11L17.24,9.94L15.12,7.82L13,9.94M8.88,9.94L9.94,11L11,9.94L8.88,7.82L6.76,9.94L7.82,11L8.88,9.94M12,17.5C14.33,17.5 16.31,16.04 17.11,14H6.89C7.69,16.04 9.67,17.5 12,17.5Z" />
        </svg>
    );
};
export const IconEmoticonExited = ({ width, height, fill }: IconProps) => {
    return (
        <svg
            fill={fill}
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            id="mdi-emoticon-excited"
            width={width}
            height={height}
            viewBox="0 0 24 24"
        >
            <path d="M12,2C6.47,2 2,6.47 2,12C2,17.53 6.47,22 12,22A10,10 0 0,0 22,12C22,6.47 17.5,2 12,2M8.88,7.82L11,9.94L9.94,11L8.88,9.94L7.82,11L6.76,9.94L8.88,7.82M12,17.5C9.67,17.5 7.69,16.04 6.89,14H17.11C16.31,16.04 14.33,17.5 12,17.5M16.18,11L15.12,9.94L14.06,11L13,9.94L15.12,7.82L17.24,9.94L16.18,11Z" />
        </svg>
    );
};

export const IconSurvey = ({ width, height }: Omit<IconProps,'fill'>) => {
    return (
        <div style={{ width: width, height: height }}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                x="0"
                y="0"
                enableBackground="new 0 0 460 460"
                version="1.1"
                viewBox="0 0 460 460"
                xmlSpace="preserve"
            >
                <path
                    fill="#3CA988"
                    d="M230 0C124.526 0 35.634 70.997 8.507 167.807l451.26 51.752C454.309 97.379 353.526 0 230 0z"
                ></path>
                <path
                    fill="#268D74"
                    d="M459.754 219.56L292.78 52.587 206.064 200.09 24.467 333.31C62.31 408.448 140.129 460 230 460c127.025 0 230-102.975 230-230 0-3.5-.092-6.979-.246-10.44z"
                ></path>
                <path
                    fill="#196153"
                    d="M115.11 167.81H8.515C2.972 187.592 0 208.448 0 230c0 17.11 1.884 33.778 5.428 49.822L137.08 291.4l-21.97-123.59z"
                ></path>
                <path
                    fill="#082B25"
                    d="M51.473 375h63.637l21.97-103.6H3.724c7.053 38.803 23.846 74.206 47.749 103.6z"
                ></path>
                <path
                    fill="#28D5C9"
                    d="M376.08 167.81c12.23 0 22.15 9.91 22.15 22.15 0 12.23-9.92 22.14-22.15 22.14h-72.34l20-44.29h52.34zM367.01 222.1c12.23 0 22.15 9.92 22.15 22.16 0 12.23-9.92 22.14-22.15 22.14h-63.27v-44.3h63.27zM357.94 276.4c12.23 0 22.15 9.92 22.15 22.15 0 12.24-9.92 22.15-22.15 22.15h-54.2v-44.3h54.2zM348.87 330.7c12.23 0 22.14 9.92 22.14 22.15 0 12.23-9.91 22.15-22.14 22.15h-25.13l-20-44.3h45.13z"
                ></path>
                <path
                    fill="#5AECE9"
                    d="M274.14 167.81c17.54-26.92 26.22-61.55 26.22-96.91 0-14.31-11.59-25.9-25.89-25.9-14.31 0-25.91 11.59-25.91 25.9 0 53.52-43.38 96.91-96.9 96.91l-30 103.59 30 103.6h172.08V167.81h-49.6z"
                ></path>
                <path fill="#C2FBFF" d="M151.66 271.4L151.66 375 115.11 375 115.11 271.4 133.385 251.4z"></path>
                <path fill="#FFF" d="M115.11 167.81H151.66V271.4H115.11z"></path>
                <circle cx="298.938" cy="189.955" r="10" fill="#C2FBFF"></circle>
                <circle cx="298.938" cy="244.253" r="10" fill="#C2FBFF"></circle>
                <circle cx="298.938" cy="298.552" r="10" fill="#C2FBFF"></circle>
                <circle cx="298.938" cy="352.85" r="10" fill="#C2FBFF"></circle>
            </svg>
        </div>
    );
};
