import { SSO_API_URL } from "@/constants";
import useStore from "@/store/main/action";
import { customFetch, handleRedirectSSO } from "@/utils/Auth";
import { useEffect } from "react";
import { Outlet } from "react-router-dom";

export const Component = () => {
	const { setUser, setUserInfo, setIsLogged, cSessionToken } = useStore();

	const handleValidaSesion = async () => {
		try {
			const { response } = await customFetch({
				url: `${SSO_API_URL}/Valida/Sesion`,
				method: 'POST',
				headers: { Authorization: `BEARER ${cSessionToken}` },
			})();

			if (!response.Success) {
				setIsLogged(false);
				throw 'Sesión Inválida';
			}

			setUser(response.Data);
			setUserInfo(response.Data);
			setIsLogged(true);
		} catch (error) {
			handleRedirectSSO();
		}
    };

	useEffect(() => {
        handleValidaSesion();
	}, []);

	return (
		<Outlet />
	);
}

export { Component as ProtectedRoute }
