import { createContext, useReducer } from 'react';
import { actionType, appBarReducer , appBarState, UserInfo } from './reducer';

const initialValues: appBarState = {
    userInfo: {} as UserInfo,
    primaryColor: '#970F0F',
    icon: '/grupoPanamaH.png',
    backgroundImg: '',
    cDescripcion: '',
    surveytoken: null,
    loading: true,
    back: false,
    oUser: {} as UserInfo,
    bIsLogged: false,
    cSessionToken: localStorage.getItem('SSO_CREDENTIALS') ?? '',
}

type reducerType = [appBarState, React.Dispatch<actionType>];
function Store({ children }: { children: JSX.Element | JSX.Element[] }) {
    const [state, dispatch] = useReducer(appBarReducer, initialValues);

    return (
		<Context.Provider 
			value={[state, dispatch]}
		>
			{children}
		</Context.Provider>
	);
}

export const Context = createContext([] as unknown as reducerType);

export default Store;
