// text.ts
const text = {
    font: {
      title: '"Oswald", sans-serif',
      body: '"Roboto Mono", monospace',
      cursive: '"Beth Ellen", cursive',
    },
    size: {
      h1: '6rem',
      h2: '3.75rem',
      h3: '3rem',
      h4: '2.125rem',
      h5: '1.5rem',
      h6: '1.2rem',
      subtitle1: '1rem',
      subtitle2: '0.875rem',
      caption: '0.75rem',
    },
    weight: {
      light: 300,
      regular: 400,
      bold: 700,
    }
  }
  export default text;