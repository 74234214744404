import { getIsTouchDevice } from '@/utils';
import { Grid, TextField } from './styled';
import { useMediaQuery } from '@mui/material';

interface props {
    label?: React.ReactNode
    error?: boolean
    helperText?: React.ReactNode
    disabled ?: boolean
    value: string
    handleValue: (value: string) => void
    paddingBottom?: string
}

const TextFieldComponent = ({ value, handleValue, disabled, helperText, paddingBottom, error, label } : props) => {
    const xs = useMediaQuery('(min-width: 500px)');
    const isTouchDevice = xs && getIsTouchDevice();
    return (
        <Grid item padding={paddingBottom ?? '0 0 15px 0'}>
            <TextField
                label={label}
                error={error}
                font={isTouchDevice ? '25px' : undefined}
                multiline
                maxRows={4}
                fullWidth
                helperText={helperText}
                onChange={(e) => handleValue(e.target.value)}
                disabled={disabled}
                value={value}
            />
        </Grid>
    );
};

export default TextFieldComponent;
