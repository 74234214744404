import styled from 'styled-components';
import GridMUI from '@mui/material/Grid';
import TextFieldMUI from '@mui/material/TextField';

interface GridProps {
  padding?: string
}

export const Grid = styled(GridMUI)<GridProps>`
  padding: ${(props) => props.padding};
`;

export const TextField = styled(TextFieldMUI)` 
  input {
    background-color: #ffffff;
    border-radius: 4px;
    padding: 10px;
  }
`;
