import styled from 'styled-components';
import GridMUI from '@mui/material/Grid';
import ButtonBaseMUI from '@mui/material/ButtonBase';
import DrawerMUI from '@mui/material/Drawer';

export const Drawer = styled(DrawerMUI)``;
export const SideBarButton = styled(ButtonBaseMUI)`
    padding: 8px;
    position: fixed;
    left: 0;
    z-index: 100;
    margin-top: 27px;
    background-color: white;
    border-radius: 0 4px 4px 0;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.5);
`;

interface SideBarProps {
    width?: string;
}

export const SideBar = styled(GridMUI)<SideBarProps>`
    padding-top: 10px;
    background-color: #ffffff !important;
    width: ${(props) => props.width};
    z-index: 2;
    height: 100%;
    box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
`;

interface GridProps {
    padding?: string;
}

export const Grid = styled(GridMUI)<GridProps>`
    padding: ${(props) => props.padding};
`;
