import styled, { keyframes } from 'styled-components';
import GridMUI from '@mui/material/Grid';
import CardMediaMUI from '@mui/material/CardMedia';
import LogoutIconMUI from '@mui/icons-material/Logout';
import IconButtonMUI from '@mui/material/IconButton';

export const IconButton = styled(IconButtonMUI)`
    background-color: transparent !important;
`;

interface IAdmin {
	admin?: 'true' | unknown
}

export const AppBar = styled(GridMUI)<IAdmin>`
    ${(props) => props.admin === 'true' ? `
		background-color: #FFFFFF;
		box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
		padding: 10px;
		z-index: 10;
	` : `padding: 10px 10px 20px 10px;`}
`;

const slideInLeft = keyframes`
	0% {
		transform: translateX(-100%);
	}
	100% {
		transform: translateX(0);
	}
`;
export const TransitionContainer = styled.div`
    animation: 1s ease-out 0s 1 ${slideInLeft};
`;

export const LogoutIcon = styled(LogoutIconMUI)<IAdmin>`
    color: #686868 !important;
`;

interface Gridprops {
	padding?: string
	maxheight?: string
}

export const Grid = styled(GridMUI)<Gridprops>`
    padding: ${(props) => props.padding};
    max-height: ${(props) => props.maxheight};
`;

interface MainContainerProps extends IAdmin {
	padding?: string | null
	bggrey?: 'true' | unknown
}

export const MainContainer = styled(GridMUI)<MainContainerProps>`
    padding: ${(props) => props.padding};
    min-height: 100vh;
    ${(props) => props.bggrey === 'true'
            ? `background: -webkit-linear-gradient(360deg, #dee1e1 10%, #f4f4f4 360%); background: -moz-linear-gradient(360deg, #dee1e1 10%, #f4f4f4 360%); background: linear-gradient(360deg, #dee1e1 10%, #f4f4f4 360%)`
            : `background-color: ${props.admin === 'true' ? '#FAFAFA' : 'transparent'}
	`};
`;

export const BackgroundImg = styled(GridMUI)<{ url?: string }>`
    background-image: url(${(props) => props.url});
    background-repeat: no-repeat;
    background-attachment: fixed;
    transition: background-image 0.5s;

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    opacity: 0.3;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
`;
// filter: blur(5px);

interface IconContainerProps {
	maxwidth?: string
	maxheight?: string
    component?: string
}

export const IconContainer = styled(CardMediaMUI)<IconContainerProps>`
    max-width: ${(props) => props.maxwidth};
    max-height: ${(props) => props.maxheight};
`;

/* 
  max-width: 115px;
  max-height: 115px;

*/
