import { Grid, useMediaQuery } from '@mui/material';
import { getIsTouchDevice } from '@/utils';
import { Typography, RaitingNumber } from '../../styled';
import { RAITING_NUMBERS } from '@/constants';
import { Pregunta, SurveyStepsHandler } from '@/hooks/useSurvey';

interface props {
    step: Pregunta;
    handleSurveySteps: SurveyStepsHandler;
    iStep: number;
    finalStep: boolean;
    handleNext: () => void;
}

const Raiting10Step = ({ step, handleSurveySteps, iStep, handleNext, finalStep }: props) => {
    const isTouchDevice = getIsTouchDevice();
    const xs = useMediaQuery('(min-width: 500px)');

    return (
        <Grid width={'100%'} container direction="column" padding="0 5px 0 0">
            <Grid
                justifyContent={isTouchDevice && xs ? 'center' : 'flex-start'}
                padding={isTouchDevice ? '0 0 40px 0' : '0 0 10px 0'}
                container
            >
                <Typography
                    font={isTouchDevice ? (xs ? '27px' : '20px') : undefined}
                    align={isTouchDevice ? 'center' : 'right'}
                    variant={isTouchDevice ? undefined : 'h6'}
                >
                    <strong>{step.cPregunta}</strong>
                </Typography>
            </Grid>
            <Grid padding={isTouchDevice ? undefined : '20px 0 0 0'} container justifyContent="center">
                {RAITING_NUMBERS.map((item) => (
                    <RaitingNumber
                        isTouch={isTouchDevice}
                        active={(item <= Number(step?.cValor)).toString()}
                        grade={item}
                        key={item}
                    >
                        <button
                            className="button"
                            onClick={() => {
                                handleSurveySteps(iStep, 'cValor', String(item));
                                if (!finalStep) {
                                    setTimeout(() => {
                                        handleNext();
                                    }, 150);
                                }
                            }}
                        >
                            <Typography>
                                <strong>{item}</strong>
                            </Typography>
                        </button>
                    </RaitingNumber>
                ))}
            </Grid>
        </Grid>
    );
};

export default Raiting10Step;
