import styled, { keyframes } from 'styled-components';
import GridMUI from '@mui/material/Grid';
import TypographyMUI from '@mui/material/Typography';
import CheckIconMUI from '@mui/icons-material/CheckCircle';
export const CheckIcon = styled(CheckIconMUI)`
    color: #488b70;
    font-size: 150px;
`;

const show = keyframes`
0%{
  transform:  scale(1)  ;
}
15% {
  transform:  scale(.75)  ;
}
30% {
  transform:  scale(1) ;
}
45% {
  transform:  scale(.85)  ;
}
60% {
  transform:  scale(1)  ;
}
75% {
  transform:  scale(.95)  ;
}
100% {
  transform:  scale(1)  ;
}
`;

const showOpacity = keyframes`
from{
  opacity:0;
}
to{
  opacity:1;
}
`;

interface TypographyProps {
  padding?: string
}

export const Typography = styled(TypographyMUI)<TypographyProps>`
    padding: ${(props) => props.padding};
`;

export const DoneContainer = styled(GridMUI)`
    padding: 10px 0 0 0;
    animation:
        ${show} 0.6s,
        ${showOpacity} 0.6s;
`;
