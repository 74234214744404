import Store from '@/store/main/store';
import { GeneralLayout } from '@/components';
import '../styles/globals.css';
import { Outlet } from 'react-router-dom';
import { StyleSheetManager } from 'styled-components';
import { shouldForwardProp } from '@/utils';
import { Toaster } from 'sonner';

export const Root = () => {
    return (
		<StyleSheetManager enableVendorPrefixes shouldForwardProp={shouldForwardProp}>
            <Toaster position='top-right' richColors />
            <Store>
                <GeneralLayout>
                    <Outlet />
                </GeneralLayout>
            </Store>
        </StyleSheetManager>
    );
}
