import { Fragment } from 'react';
import { Typography, useMediaQuery } from '@mui/material';
import { useWizard, useStoreState } from 'hooks';
import { Button, SurveyLayoutContainer, Grid, BottomBarContainer, NextIcon, BackIcon, CheckCircleIcon } from './styled';
import Steps from './containers/index.tsx';
import { handleDisable } from './helpers';
import { Button as DoneButton } from '../UserInfoForm/styled.tsx';
import _ from 'lodash';
import { CarrouselSurvey, SurveyLayout } from '@/components';
import { MetaInfo, Pregunta, PreguntaRespuesta, PreguntasDetalle, SurveyStepsDetailsHandler, SurveyStepsHandler } from '@/hooks/useSurvey';

interface props {
    disabled?: boolean,
    steps: Pregunta[],
    hideBack?: boolean,
    handleSurveySteps: SurveyStepsHandler,
    handleSurveyStepsDetails: SurveyStepsDetailsHandler,
    hideNext?: boolean,
    disabledNext: boolean,
    handleMenu: () => void,
    handleDone: () => Promise<void>,
    metaInfo: MetaInfo[],
    details: PreguntasDetalle[],
    answers: PreguntaRespuesta[]
};

// https://mui.com/components/steppers/#main-content
const SurveyWizardComponent = ({
    handleSurveySteps,
    handleSurveyStepsDetails,
    steps,
    hideBack,
    hideNext,
    disabledNext,
    handleMenu,
    handleDone,
    // details,
    answers,
}: props) => {
    const { primaryColor } = useStoreState();
    const {
        activeStep,
        finalStep,
        handleNext,
        handleBack,
        numItems,
        state,
        isTouchDevice,
        isTouchDeviceString,
    } = useWizard(steps, handleMenu, handleDone);
    const xs = useMediaQuery('(min-width: 600px)');

    const buttonProps = {
        disableFocusRipple: isTouchDevice,
        disableRipple: isTouchDevice,
        disableElevation: isTouchDevice,
        primarycolor: primaryColor,
    };

    const item = steps[activeStep];
    const disabled = handleDisable(item); //item.nTipo != 6 && handleDisable(item) || handleTableStepDisable(_.filter(details,(detail)=>detail.nPregunta == item.nPregunta));

    const BottomBar = () => {
        return (
            <BottomBarContainer
                bgcolor={isTouchDevice ? primaryColor : '#ffffff'}
                istouchdevice={isTouchDeviceString}
                container
                justifyContent="space-between"
                alignItems="center"
            >
                <Grid item>
                    {hideBack ? null : (
                        <Button
                            back={(state.dir === 'PREV' && state.sliding).toString()}
                            {...buttonProps}
                            istouchdevice={isTouchDeviceString}
                            startIcon={isTouchDevice && <BackIcon />}
                            onClick={handleBack}
                            variant="contained"
                        >
                            <Typography>{xs ? 'Anterior' : ''}</Typography>
                        </Button>
                    )}
                </Grid>
                <Grid item>
                    {hideNext ? null : finalStep && !isTouchDevice ? (
                        <DoneButton disabled={disabled || disabledNext} onClick={handleDone}>
                            <Typography>Finalizar</Typography>
                        </DoneButton>
                    ) : (
                        <Button
                            next={(state.dir === 'NEXT' && state.sliding).toString()}
                            {...buttonProps}
                            endIcon={
                                isTouchDevice &&
                                (!finalStep ? (
                                    <NextIcon disabledicon={(disabled || disabledNext).toString()} />
                                ) : (
                                    <CheckCircleIcon disabledicon={(disabled || disabledNext).toString()} />
                                ))
                            }
                            istouchdevice={isTouchDeviceString}
                            onClick={handleNext}
                            disabled={disabled || disabledNext}
                        >
                            <Typography>{xs ? (finalStep ? 'Finalizar' : 'Siguiente') : ''}</Typography>
                        </Button>
                    )}
                </Grid>
            </BottomBarContainer>
        );
    };

    return (
        <>
            <SurveyLayout>
                <Grid container direction="column" justifyContent="space-between">
                    <SurveyLayoutContainer item container>
                        <CarrouselSurvey numItems={numItems + 1} state={state}>
                            {steps.map((step, index) => {
                                //const helper = _.filter(metaInfo, o => o.nTipo === 2 && o.nPregunta === step.nPregunta)[0]
                                const answersStep = _.filter(answers, (o) => o.nPregunta === step.nPregunta);
                                // const detailsStep = _.filter(details, (o) => o.nPregunta === step.nPregunta);

                                return (
                                    <Fragment key={index}>
                                        {step.bMostrarSeccion ? (
                                            <Grid
                                                justifyContent={isTouchDevice && xs ? 'center' : 'flex-start'}
                                                padding="15px 0 15px 0"
                                                container
                                            >
                                                <Typography variant={isTouchDevice ? 'h4' : 'h5'}>
                                                    <strong>{step.cSeccion}</strong>
                                                </Typography>
                                            </Grid>
                                        ) : (
                                            <Grid padding="15px 0 0 0"></Grid>
                                        )}

                                        <Steps
                                            finalStep={index + 1 === steps.length}
                                            handleNext={handleNext}
                                            iStep={index}
                                            step={step}
                                            // details={detailsStep}
                                            answers={answersStep}
                                            handleSurveySteps={handleSurveySteps}
                                            handleSurveyStepsDetails={handleSurveyStepsDetails}
                                        />
                                    </Fragment>
                                );
                            })}
                        </CarrouselSurvey>
                    </SurveyLayoutContainer>
                    {!isTouchDevice && <BottomBar />}
                </Grid>
            </SurveyLayout>
            {isTouchDevice && <BottomBar />}
        </>
    );
};

export default SurveyWizardComponent;
