import styled, { keyframes } from 'styled-components';
import GridMUI from '@mui/material/Grid';
import TypographyMUI from '@mui/material/Typography';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const colorFade = keyframes`
	from {
		background-color: #ffffff;
	}
	to {
		background-color: #f7f7f7;
	}
`;

export const Typography = styled(TypographyMUI)`
    color: #424242;
`;
export const ArrowFoward = styled(ArrowForwardIosIcon)`
    color: #424242;
`;
export const CardContainer = styled(GridMUI)`
    padding: 20px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    margin-bottom: 3px;
    &:hover {
        cursor: pointer;
        animation-name: ${colorFade};
        animation-duration: 0.2s;
        animation-fill-mode: forwards;
    }
`;
export const EmptyCard = styled(GridMUI)`
    height: 72px;
`;
