import styled from 'styled-components';
import GridMUI from '@mui/material/Grid';
import TypographyMUI from '@mui/material/Typography';

interface TypographyProps {
	grey?: 'true' | string
	font?: string
}

export const Typography = styled(TypographyMUI)<TypographyProps>`
    color: ${(props) => (props.grey === 'true' ? '#666666' : null)};
    font-size: ${(props) => props.font};
`;

interface ItemProps {
	current?: 'true' | string
	primarycolor?: string
}

export const Item = styled(GridMUI)<ItemProps>`
    padding: 15px 0 15px 0;
    border-bottom: 1px solid #e2e2e2;
    padding: 16px;
    width: 100%;
    color: ${(props) => (props.current === 'true' ? props.primarycolor : '#999999')};

    &:hover {
        cursor: pointer;
        color: #848484 !important;
        svg {
            color: #848484;
        }
    }
`;

export const MenuItemTypography = styled(Typography)`
    padding: 0 10px 0 10px;
`;

interface ScrollContainer {
	maxheight?: string
}

export const ScrollContainer = styled.div<ScrollContainer>`
    max-height: ${(props) => props.maxheight};
    overflow-y: scroll !important;
    ::-webkit-scrollbar {
        -webkit-appearance: none;
    }

    ::-webkit-scrollbar:vertical {
        width: 5px;
    }

    ::-webkit-scrollbar:horizontal {
        height: 12px !important;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #cc3528;
        border-radius: 0px;
        border: 0px solid #ffffff;
    }

    ::-webkit-scrollbar-track {
        border-radius: 0px;
        background-color: transparent;
    }
`;
