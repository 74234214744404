import { getIsTouchDevice } from '@/utils';
import { useState, useEffect } from 'react';

const useIsTouchDevice = () => {
    const isTouchDeviceHelper = getIsTouchDevice();
    const [isTouchDevice, setisTouchDevice] = useState(false);

    useEffect(() => {
        setisTouchDevice(isTouchDeviceHelper);
    }, [isTouchDeviceHelper]);

    return {
        isTouchDevice,
    };
};
export default useIsTouchDevice;
