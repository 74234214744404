import { useState, useEffect } from 'react';
import useStore from '@/store/main/action';
import { API_URL } from '@/constants';

const useLogin = () => {
	const [status, setstatus] = useState(0);
    const [loading, setloading] = useState(false);
    const [user, setuser] = useState('');
    const [password, setpassword] = useState('');

    const { 
		setUserInfo, 
		storeState, 
		removeAssets, 
		resetReducer, 
		primaryColor, 
		userInfo 
	} = useStore();

    useEffect(() => {
        removeAssets();
        // alert(`${document.body.clientHeight} ${document.body.clientWidth}`);
    }, []);

    const handleLogin = async () => {
        setloading(true);
        if (password !== '' && user !== '') {
            const datos = {
                cNombreUsuario: user,
                cContrasenia: password,
            };

            await fetch(`${API_URL}Encuestas/ObtenerUsuario`, {
                body: JSON.stringify(datos),
                method: 'POST',
                headers: {
                    'Content-type': 'application/json',
                },
            })
                .then((res) => res.json())
                .catch(() => {
                    setstatus(4);
                })
                .then((response) => {
                    if (response) {
                        if (response.success && response.Result.data && response.Result.token) {
                            localStorage.setItem('SSO_CREDENTIALS', response.Result.token);
                            setstatus(2);
                            setUserInfo({
                                ...response.Result.data,
                            });
                        } else {
                            setstatus(3);
                        }
                    } else {
                        setstatus(4);
                    }
                    setloading(false);
                });
        }
    };

    return {
        status,
        setstatus,
        loading,
        setloading,
        user,
        setuser,
        password,
        setpassword,
        handleLogin,
        storeState,
        userInfo,
        primaryColor,
        resetReducer,
    };
};

export default useLogin;
