import { Typography } from '@mui/material';
import useIsTouchDevice from 'hooks/useIsTouchDevice';
import {ExitDialog} from 'components';
import {Button, ArrowBackIcon} from './styled';
import { useDialog } from '@/hooks';
import { useNavigate } from 'react-router-dom';

const MainMenuBackButtonComponent = () => {
    const {
        handleCancelar,
        handleOpenDialog,
        open
    } = useDialog();

    const navigate = useNavigate();
    const {
        isTouchDevice,
    } = useIsTouchDevice();

    const handleMenuPrincipal = () => {
        navigate(`/`);
    };

    const buttonProps = {
        disableFocusRipple: isTouchDevice,
        disableRipple: isTouchDevice,
        disableElevation: isTouchDevice,
    };

    return (
        <>
            <Button
                {...buttonProps}
                istouchdevice={isTouchDevice.toString()}
                startIcon={<ArrowBackIcon blackicon={(!isTouchDevice).toString()} />} 
                onClick={handleOpenDialog} 
                variant='contained'
            >
                <Typography>Menú principal</Typography>
            </Button>

            <ExitDialog 
                title="¿Está seguro que desea salir al menú principal?"
                subtitle="Los datos de esta encuesta se perderán."
                open={open}
                handleCancelar={handleCancelar}
                handleAceptar={handleMenuPrincipal}
            />
        </>
    )
}
export default (MainMenuBackButtonComponent);
