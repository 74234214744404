import styled from 'styled-components';
import GridMUI from '@mui/material/Grid';
import TypographyMUI from '@mui/material/Typography';
import CheckIconMUI from '@mui/icons-material/CheckCircle';

interface TypographyProps {
	padding: number | string
}

export const Typography = styled(TypographyMUI)<TypographyProps>`
    padding: ${(props) => props.padding};
`;

export const DoneContainer = styled(GridMUI)`
    padding: 10% 0 0 0;
`;

export const CheckIcon = styled(CheckIconMUI)`
    color: #488b70;
    font-size: 150px;
`;
