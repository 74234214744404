export const getGlobalTheme = (primaryColor: string) => {
  return {
    palette: {
      primary: {
        main: primaryColor || '#970F0F',
      },
      secondary: {
        main: '#ffffff',
      },
    }
  }
};