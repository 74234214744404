import RaitingStep from './RaitingStep';
import Raiting10Step from './Raiting10Step';
import TextTareaStep from './TextTareaStep';
// import TableStep from './TableStep';
import RadioSelectStep from './RadioSelectStep';
import { Pregunta, PreguntaRespuesta, SurveyStepsDetailsHandler, SurveyStepsHandler } from '@/hooks/useSurvey';

//TIPOS
/*
	1 = Muy Insatisfecho - Muy Satisfecho
	2 = SI y NO
*/

interface props {
    step: Pregunta,
    handleSurveySteps: SurveyStepsHandler,
	handleSurveyStepsDetails: SurveyStepsDetailsHandler,
    iStep: number,
    handleNext: () => void,
	answers: PreguntaRespuesta[]
    finalStep: boolean
};

const Steps = ({step, /*details,*/ answers, finalStep, handleSurveySteps, iStep, handleNext} : props) => {
	let Step = null;

	(step.nTipo === 1 || step.nTipo === 2) && 
		(Step = <RaitingStep finalStep={finalStep} handleNext={handleNext} iStep={iStep} step={step} handleSurveySteps={handleSurveySteps} />);

	(step.nTipo === 3) &&
		(Step = <TextTareaStep iStep={iStep} step={step} handleSurveySteps={handleSurveySteps} />);
	
	(step.nTipo === 4) &&
		(Step = <Raiting10Step handleNext={handleNext} finalStep={finalStep}  iStep={iStep} step={step} handleSurveySteps={handleSurveySteps} />);
	
	(step.nTipo === 5) &&
		(Step = <RaitingStep finalStep={finalStep} handleNext={handleNext} iStep={iStep} step={step} handleSurveySteps={handleSurveySteps} />);
	
	/*(step.nTipo === 6) &&
		(Step = <TableStep finalStep={finalStep} handleNext={handleNext} iStep={iStep} step={step} details={details} responses={responses} handleSurveySteps={handleSurveySteps} handleSurveyStepsDetails={handleSurveyStepsDetails} />);*/
	
	(step.nTipo === 6) &&
		(Step = <RadioSelectStep handleNext={handleNext} finalStep={finalStep} answers={answers} iStep={iStep} step={step} handleSurveySteps={handleSurveySteps} />)
	
    //if(step.nTipo === 1) return <RaitingStep finalStep={finalStep} handleNext={handleNext} iStep={iStep} step={step} handleSurveySteps={handleSurveySteps} />;
    //if(step.nTipo === 2) return <RaitingStep finalStep={finalStep} handleNext={handleNext} iStep={iStep} step={step} handleSurveySteps={handleSurveySteps} />;
    //if(step.nTipo === 3) return <TextTareaStep iStep={iStep} step={step} handleSurveySteps={handleSurveySteps} />;
    
    //if(step.nTipo === 4) return <Raiting10Step handleNext={handleNext} finalStep={finalStep}  iStep={iStep} step={step} handleSurveySteps={handleSurveySteps} />;

    //if(step.nTipo === 5) return <RaitingStep finalStep={finalStep} handleNext={handleNext} iStep={iStep} step={step} handleSurveySteps={handleSurveySteps} />;	

    return Step ?? null;
};

export default (Steps);
