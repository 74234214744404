import styled from 'styled-components';
import GridMUI from '@mui/material/Grid';
// ${props=>props.istouchdevice === 'true' ? '100%' : '80%'};
export const SurveyLayoutContainer = styled(GridMUI)<props>`
    padding: 20px 32px 20px 32px;
    border-right: 20px solid ${(props) => props.primarycolor};
    border-left: 20px solid ${(props) => props.primarycolor};
    width: 80%;
    max-width: 760px;
    background-color: #ffffff !important;
    z-index: 1;
    border-radius: 5px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    min-height: 350px;
    margin-top: 5%;
    @media (max-width: 1263px) {
        width: 95%;
        max-width: 850px;
    }
`;

interface props {
    primarycolor?: string
    small?: string
    istouchdevice?: string
}

export const SurveyLayoutContainerTouch = styled(GridMUI)<props>`
    padding: 8% 32px 20px 32px;
    border-right: 35px solid ${(props) => props.primarycolor};
    border-left: 35px solid ${(props) => props.primarycolor};
    width: 100%;
    background-color: #ffffff !important;
    z-index: 1;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    @media (max-width: 700px) {
        border-right: 15px solid ${(props) => props.primarycolor};
        padding: 20px 12px 20px 12px;
        border-left: 15px solid ${(props) => props.primarycolor};
    }
    @media (max-height: 700px) {
        padding: 10px 32px 20px 32px;
    }
`;
