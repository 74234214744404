import { useState, useEffect } from 'react';

const useTimer = (counterTimer: number, handleReset: () => void) => {
    const [progress, setProgress] = useState(counterTimer);
    useEffect(() => {
        const timer = setInterval(() => {
            setProgress((prevProgress) => prevProgress - 1);
        }, 800);
        return () => {
            clearInterval(timer);
        };
    }, []);
    useEffect(() => {
        if (progress <= 0) {
            handleReset();
        }
    }, [progress]);

    return {
        progress,
    };
};

export default useTimer;
