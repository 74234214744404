import { Grid, TextField, LockIcon } from './styled';
import { LoginContainer } from '@/views/login/styled';
import { useTimer } from 'hooks';
import { keyPress } from './helpers';
import { Typography } from '@mui/material';
import { Pin } from '@/hooks/usePin';

interface props {
    pinValue: Pin,
    handlePinvalue: (i: number, e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void,
    status: number,
    loading: boolean,
    handleReset: () => void
};

const TokenAuth = ({ pinValue, handlePinvalue, status, loading, handleReset } : props) => {
    const { progress } = useTimer(30, handleReset);

    const errorToken = status > 2;
    return (
        <Grid container justifyContent="center">
            <LoginContainer item container direction="column">
                <Grid item container justifyContent="center">
                    <LockIcon />
                </Grid>
                <Grid item padding="15px 0 0 0" container justifyContent="center">
                    <Typography variant="h6" align="center">
                        <strong>Página restingida</strong>
                    </Typography>
                </Grid>
                <Grid justifyContent="center" item container>
                    <Typography
                        align="center"
                        variant="subtitle2"
                        color="#757575"
                    >{`Redireccionando en ${progress}`}</Typography>
                </Grid>

                <Grid justifyContent="center" item padding="0 0 20px 0" container>
                    <Typography color="#666666" variant="caption">
                        Por favor ingrese pin de seguridad:
                    </Typography>
                </Grid>

                {errorToken && (
                    <Grid item padding="0px 0 3px 0" container justifyContent="center">
                        <Typography color="#E64A3B" variant="caption" align="center">
                            <strong>
                                {status === 4 ? 'Ha ocurrido un error, porfavor intente de nuevo.' : 'Pin inválido'}
                            </strong>
                        </Typography>
                    </Grid>
                )}
                <form name="inputnumber" autoComplete="off">
                    <Grid item container justifyContent="center">
                        {['pin1', 'pin2', 'pin3', 'pin4'].map((item, i) => (
                            <Grid key={item} item padding="5px">
                                <TextField
                                    type="number"
                                    error={errorToken}
                                    disabled={loading}
                                    id={item}
                                    value={pinValue[item as keyof Pin]}
                                    onChange={(e) => {
                                        handlePinvalue(i, e);
                                    }}
                                    onKeyDown={(e) => keyPress(e, i, pinValue)}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </form>
            </LoginContainer>
        </Grid>
    );
};

export default TokenAuth;
