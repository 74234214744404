import { Pin } from "@/hooks/usePin";

export const keyValidation = (e: React.KeyboardEvent<HTMLDivElement>) => {
    const validCharCodes : string[] = [...Array(10)].reduce((acc, _, index) => [...acc, index.toString()], []);

    if (!validCharCodes.includes(e.key)) {
        e.preventDefault();
    }
};

export const keyPress = (e: React.KeyboardEvent<HTMLDivElement>, i: number, pinValue: Pin) => {
    const index = `pin${(i + 1)}` as keyof Pin;

    const isDeleting = e.code === 'Delete' || e.code === 'Backspace';

    if (isDeleting && pinValue[index] === '') {
        if (i !== 0) {
            e.preventDefault();
            document.getElementById(`pin${i}`)?.focus();
        }
    } else if (!isDeleting) {
        keyValidation(e);
    }
};
