import {
    IconEmoticonSad,
    IconEmoticonSadOutline,
    IconEmoticonConfusedOutline,
    IconEmoticonConfused,
    IconEmoticonNeutralOutline,
    IconEmoticonNeutral,
    IconEmoticonExited,
    IconEmoticonExitedOutline,
    IconEmoticonHappy,
    IconEmoticonHappyOutline,
} from '@/assets/icons';

export const getIconsYesNoIcons = (iconSize: string, primaryColor: string) => {
    const propschecked = {
        width: iconSize,
        height: iconSize,
        fill: primaryColor,
    };
    const props = {
        width: iconSize,
        height: iconSize,
        fill: '#757575',
    };

    return [
        {
            icon: <IconEmoticonHappyOutline {...props} />,
            checkedIcon: <IconEmoticonHappy {...propschecked} />,
            label: 'Sí',
            value: 1,
        },
        {
            icon: <IconEmoticonSadOutline {...props} />,
            checkedIcon: <IconEmoticonSad {...propschecked} />,
            label: 'No',
            value: 2,
        },
    ];
};
export const getIconsEmotionalIcons = (iconSize: string, primaryColor: string) => {
    const propschecked = {
        width: iconSize,
        height: iconSize,
        fill: primaryColor,
    };
    const props = {
        width: iconSize,
        height: iconSize,
        fill: '#757575',
    };
    return [
        {
            icon: <IconEmoticonSadOutline {...props} />,
            checkedIcon: <IconEmoticonSad {...propschecked} />,
            label: 'Muy Insatisfecho',
            value: 1,
        },
        {
            icon: <IconEmoticonConfusedOutline {...props} />,
            checkedIcon: <IconEmoticonConfused {...propschecked} />,
            label: 'Insatisfecho',
            value: 2,
        },
        {
            icon: <IconEmoticonNeutralOutline {...props} />,
            checkedIcon: <IconEmoticonNeutral {...propschecked} />,
            label: 'Neutral',
            value: 3,
        },
        {
            icon: <IconEmoticonHappyOutline {...props} />,
            checkedIcon: <IconEmoticonHappy {...propschecked} />,
            label: 'Satisfecho',
            value: 4,
        },
        {
            icon: <IconEmoticonExitedOutline {...props} />,
            checkedIcon: <IconEmoticonExited {...propschecked} />,
            label: 'Muy Satisfecho',
            value: 5,
        },
    ];
};

export const getIconsGradeIcons = (iconSize: string, primaryColor: string) => {
    const propschecked = {
        width: iconSize,
        height: iconSize,
        fill: primaryColor,
    };
    const props = {
        width: iconSize,
        height: iconSize,
        fill: '#757575',
    };
    return [
        {
            icon: <IconEmoticonSadOutline {...props} />,
            checkedIcon: <IconEmoticonSad {...propschecked} />,
            label: 'Muy Malo',
            value: 1,
        },
        {
            icon: <IconEmoticonConfusedOutline {...props} />,
            checkedIcon: <IconEmoticonConfused {...propschecked} />,
            label: 'Malo',
            value: 2,
        },
        {
            icon: <IconEmoticonNeutralOutline {...props} />,
            checkedIcon: <IconEmoticonNeutral {...propschecked} />,
            label: 'Regular',
            value: 3,
        },
        {
            icon: <IconEmoticonHappyOutline {...props} />,
            checkedIcon: <IconEmoticonHappy {...propschecked} />,
            label: 'Bueno',
            value: 4,
        },
        {
            icon: <IconEmoticonExitedOutline {...props} />,
            checkedIcon: <IconEmoticonExited {...propschecked} />,
            label: 'Excelente',
            value: 5,
        },
    ];
};
