import { useReducer, useState } from 'react';
import { getIsTouchDevice } from '@/utils';

export const NEXT = 'NEXT';
export const PREV = 'PREV';

export interface StateProps {
	pos: number;
	sliding: boolean;
	dir: string;
	ini: boolean;
}


const useHooks = (steps: unknown[], handleMenu: () => void, handleDone: () => void) => {
    const numItems = steps.length;
    const maxSteps = steps.length;
    const initialState = {
        pos: 0,
        sliding: false,
        dir: '',
        ini: true,
    };

    const reducer = (stateReducer: StateProps, { type }: Record<string, unknown>) => {
        switch (type) {
            case 'reset':
                return initialState;
            case PREV:
                return {
                    ...stateReducer,
                    dir: PREV,
                    sliding: true,
                    pos: stateReducer.pos === 0 ? numItems - 1 : stateReducer.pos - 1,
                };
            case NEXT:
                return {
                    ...stateReducer,
                    dir: NEXT,
                    sliding: true,
                    pos: stateReducer.pos === numItems - 1 ? 0 : stateReducer.pos + 1,
                };
            case 'stopSliding':
                return {
                    ...stateReducer,
                    sliding: false,
                };
            default:
                return stateReducer;
        }
    }

    const [state, dispatch] = useReducer(reducer, initialState);
    const isTouchDevice = getIsTouchDevice();
    const isTouchDeviceString = isTouchDevice.toString();


    const [activeStep, setActiveStep] = useState(0);
    const finalStep = activeStep === maxSteps - 1;

    const handleNext = () => {
        if (finalStep) {
            handleDone();
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
            slide(NEXT);
        }
    };

    const handleBack = () => {
        if (activeStep === 0) {
            handleMenu();
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep - 1);
            slide(PREV);
        }
    };

    const handleStepChange = (step: number) => {
        setActiveStep(step);
    };

    const slide = (dir: string) => {
        dispatch({
            type: dir,
            numItems,
        });
        setTimeout(() => {
            dispatch({
                type: 'stopSliding',
            });
        }, 50);
    };

    return {
        activeStep,
        setActiveStep,
        maxSteps,
        handleNext,
        handleBack,
        handleStepChange,
        state,
        numItems,
        slide,
        finalStep,
        isTouchDeviceString,
        isTouchDevice,
    };
};
export default useHooks;
