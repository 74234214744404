import { SurveyCard, Loading, SurveyLayout, ErrorMessage, TokenAuth } from '@/components';
import { Grid, MainLayout } from './styled';
import { Typography } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import useIsTouchDevice from 'hooks/useIsTouchDevice';
import { useLogout, usePin, useMain } from '@/hooks';
import { getRows } from './helpers';
import { appBarState } from '@/store/main/reducer';

interface props {
    primaryColor: string;
    resetReducer: () => void;
    storeState: appBarState;
}

const MainView = ({ primaryColor, resetReducer, storeState }: props) => {
    const { handleLogout } = useLogout(resetReducer, storeState);

    const errorProps = {
        subtitle: 'No tienes permiso para acceder a esta información',
        textButton: 'Aceptar',
        handleClick: handleLogout,
    };

    const { handleClickCard, encuestas, page, handleChange, notFound } = useMain();

    const { isTouchDevice } = useIsTouchDevice();

    const { pinValue, handlePinvalue, status, surveytoken, handleGoBack, loading } = usePin();

    if (surveytoken !== null) {
        return (
            <TokenAuth
                handleReset={handleGoBack}
                loading={loading}
                status={status}
                pinValue={pinValue}
                handlePinvalue={handlePinvalue}
            />
        );
    }

    if (!encuestas) {
        return <SurveyLayout>{notFound ? <ErrorMessage {...errorProps} /> : <Loading />}</SurveyLayout>;
    }

    // EncuestaAsignada
    const encuestasCount = encuestas.length;
    const encuestasPagination = encuestasCount / 5 + (encuestasCount % 5 > 0 ? 1 : 0);
    const encuestaCountPage = (page - 1) * 5;

    return (
        <Grid
            item
            xs={isTouchDevice}
            padding={isTouchDevice ? '0px' : '15px 10% 20px 10%'}
            container
            direction="column"
        >
            <MainLayout
                istouchdevice={isTouchDevice.toString()}
                bordercolor={primaryColor}
                xs={isTouchDevice}
                item
                container
                direction="column"
            >
                <Grid item padding={isTouchDevice ? '0 0 25px 0' : '0 0 15px 0'}>
                    <Typography variant={isTouchDevice ? 'h4' : 'h6'}>Encuestas</Typography>
                </Grid>
                {getRows((encuestas || []).slice(encuestaCountPage, encuestaCountPage + 5)).map((item, i) => {
                    return (
                        <SurveyCard isTouchDevice={isTouchDevice} handleClick={handleClickCard} key={i} item={item} />
                    );
                })}
                {encuestas.length > 5 && (
                    <Grid padding="10px 0 0 0">
                        <Stack spacing={2}>
                            <Pagination
                                page={page}
                                onChange={handleChange}
                                count={encuestasPagination}
                                variant="outlined"
                                shape="rounded"
                            />
                        </Stack>
                    </Grid>
                )}
            </MainLayout>
        </Grid>
    );
};

export default MainView;
