import { getIsTouchDevice } from '@/utils';
import { Grid, useMediaQuery } from '@mui/material';
import { Typography } from '../../styled';
import RadioGroupField from '@/components/RadioGroupField';
import { Pregunta, PreguntaRespuesta, PreguntasDetalle, SurveyStepsHandler } from '@/hooks/useSurvey';

interface props {
    step: Pregunta,
    details?: PreguntasDetalle[],
    answers: PreguntaRespuesta[],
    handleSurveySteps: SurveyStepsHandler,
    iStep: number,
    finalStep: boolean,
    handleNext: () => void,
};

const RadioSelectStep = ({ step, answers, handleSurveySteps, iStep, handleNext, finalStep } : props) => {
    const isTouchDevice = getIsTouchDevice();
    const xs = useMediaQuery('(min-width: 500px)');

    return (
        <>
            <Grid
                justifyContent={isTouchDevice && xs ? 'center' : 'flex-start'}
                padding={isTouchDevice ? '0 0 40px 0' : '0 0 10px 0'}
                container
            >
                <Typography
                    color={'#1b1c1d'}
                    font={isTouchDevice ? (xs ? '27px' : '20px') : undefined}
                    align={isTouchDevice ? 'center' : 'right'}
                    variant={isTouchDevice ? undefined : 'h6'}
                >
                    <strong>{step.cPregunta}</strong>
                </Typography>
            </Grid>

            <RadioGroupField
                value={String(step.nRespuesta)}
                answers={answers}
                setValue={(value) => {
                    handleSurveySteps(iStep, 'nRespuesta', Number(value));
                    !finalStep && handleNext();
                }}
            />
        </>
    );
};

export default RadioSelectStep;
