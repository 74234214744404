import styled from 'styled-components';

interface AlertContainerProps {
  open: boolean
}

export const AlertContainer = styled.div<AlertContainerProps>`
    border-radius: 6px;
    position: fixed;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 0px;
    bottom: 5%;
    z-index: 20;
    visibility: hidden;
    overflow: hidden;

    transition: all 0.3s ease;

    ${(props) => props.open ? `
      width: 250px;
      transition: all 0.3s ease;
      overflow: visible;

      div {
        visibility: visible;
        width: auto;
      }
    ` : null}
`;

interface GreenContainerProps {
  error: string
}

export const GreenContainer = styled.div<GreenContainerProps>`
    background-color: ${(props) => (props.error === 'true' ? props.theme.colors.red : props.theme.colors.green)};
    padding: 12px 10px 10px 10px;
    border-radius: 6px 0 0 6px;
    display: flex;
    z-index: 21;
    overflow: hidden;
    align-items: center;
    width: 0px;
    transition: all 0.3s ease;
    color: ${(props) => props.theme.colors.white} !important;

    svg {
        color: ${(props) => props.theme.colors.white} !important;
    }
`;

export const ShadowContainer = styled.div`
    display: flex;
    background-color: ${(props) => props.theme.colors.white};
    box-shadow:
        rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
        rgba(60, 64, 67, 0.15) 0px 2px 6px 2px !important;
    border-radius: 6px;
`;

export const BodyContainer = styled.div`
    padding: 0 2px 0 10px;
    display: flex;
    justify-content: space-between;
    border-radius: 0 6px 6px 0;
    transition: all 0.3s ease;
    overflow: hidden;
    align-items: center;
    flex: 1;
    width: 0px;
    background-color: ${(props) => props.theme.colors.white};
    span {
        overflow: hidden;
        max-height: 40px;

        padding: 5px;
    }
`;
