import { useContext } from 'react';
import { Context } from '@/store/main/store';
import { baseColor } from '@/utils';

const useStoreState = () => {
    const [storeState] = useContext(Context);
    const userInfo = storeState?.userInfo ?? {};
    const primaryColor = storeState?.primaryColor ?? baseColor;

    return {
        storeState,
        userInfo,
        primaryColor,
    };
};

export default useStoreState;
