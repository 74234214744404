import { Typography, useMediaQuery } from '@mui/material';
import { Grid, Button, TextField } from './styled';
import { SurveyLayout, MainMenuBackButton } from '@/components';
import { getIsTouchDevice } from '@/utils';
import { IconSurvey } from 'assets/icons';
import _ from 'lodash';
import { MetaInfo } from '@/hooks/useSurvey';
import { Fragment } from 'react/jsx-runtime';

interface props {
    setstatus: React.Dispatch<React.SetStateAction<number>>;
    title: string;
    metaInfo: MetaInfo[];
    disabledStart: boolean;
    handleMetaInfo: (i: number, value: string) => void;
    headers: boolean;
    status: number;
}

const UserInfoForm = ({ setstatus, title, metaInfo, handleMetaInfo, disabledStart, headers, status }: props) => {
    const xs = useMediaQuery('(min-width: 540px)');
    const isTouchDevice = xs && getIsTouchDevice();

    const metaInfoTextField = _.filter(metaInfo, (o) => o.nTipo === 1 || o.nTipo === 3);
    const metaInfoFiltered = _.filter(metaInfo, (o) => o.nTipo === 2 && o.nPregunta === 0);

    return (
        <SurveyLayout>
            {metaInfoTextField.length > 0 ? (
                <Grid padding="15px 0 5% 0" container>
                    <Typography variant={isTouchDevice ? 'h4' : 'h6'}>
                        <strong>{title}</strong>
                    </Typography>
                </Grid>
            ) : (
                <></>
            )}

            {metaInfo.map((item, i) => {
                const error = item.cValor ? item.cValor.length > 300 : false;
                return (item.nTipo === 1 || item.nTipo === 3) ? (
                    <Grid key={i} container padding="0 0 20px 0">
                        <TextField
                            helperText={error ? 'Excede los 300 caracteres.' : null}
                            font={isTouchDevice ? '25px' : undefined}
                            error={error}
                            fullWidth
                            variant="standard"
                            value={item.cValor ? item.cValor : ''}
                            label={item.cNombreMetaInfo}
                            onChange={(e) => handleMetaInfo(i, e.target.value)}
                        />
                    </Grid>
                ) : (<Fragment key={i} />)
            })}

            {metaInfoFiltered.map((item, i) => (
                <Typography key={i} variant={isTouchDevice ? 'h4' : 'h6'}>
                    <strong>{item.cNombreMetaInfo}</strong>
                </Typography>
            ))}

            {metaInfoFiltered.length > 0 ? (
                <Grid alignItems="center" item container padding="20px 0 2 0" justifyContent="center" xs>
                    <IconSurvey width="250px" height="250px"></IconSurvey>
                </Grid>
            ) : (
                <></>
            )}

            <Grid height="150px" padding="20px 0 0 0" alignItems="center" container justifyContent="space-between">
                {headers ? <MainMenuBackButton /> : <Button onClick={() => setstatus(-1)}>REGRESAR</Button>}

                <Button
                    disabledcolor={(disabledStart && metaInfoTextField.length > 0).toString()}
                    istouchdevice={isTouchDevice.toString()}
                    disabled={disabledStart && metaInfoTextField.length > 0}
                    onClick={() => setstatus(status)}
                >
                    {headers ? 'INICIAR ENCUESTA' : 'SIGUIENTE'}
                </Button>
            </Grid>
        </SurveyLayout>
    );
};

export default UserInfoForm;
