import { SurveyLayoutContainer, SurveyLayoutContainerTouch } from './styled';
import { Grid, useMediaQuery } from '@mui/material';
import useIsTouchDevice from 'hooks/useIsTouchDevice';
import { useStoreState } from 'hooks';

interface props {
    children: React.ReactNode;
}

const SurveyLayoutComponent = ({ children }: props) => {
    const { primaryColor } = useStoreState();
    const { isTouchDevice } = useIsTouchDevice();
    const xsheight = useMediaQuery('(min-height: 600px)');

    return (
        <Grid
            direction={isTouchDevice ? 'column' : undefined}
            xs
            item
            container
            justifyContent="center"
            alignItems={isTouchDevice ? undefined : 'flex-start'}
        >
            {isTouchDevice ? (
                <SurveyLayoutContainerTouch
                    xs
                    small={xsheight.toString()}
                    primarycolor={primaryColor}
                    istouchdevice={'true'}
                    item
                    container
                    direction="column"
                >
                    {children}
                </SurveyLayoutContainerTouch>
            ) : (
                <SurveyLayoutContainer
                    small={xsheight.toString()}
                    primarycolor={primaryColor}
                    istouchdevice={'false'}
                    item
                    container
                    direction="column"
                >
                    {children}
                </SurveyLayoutContainer>
            )}
        </Grid>
    );
};

export default SurveyLayoutComponent;
