import styled, { keyframes } from 'styled-components';
import GridMUI from '@mui/material/Grid';
import ButtonMUI from '@mui/material/Button';
import TypographyMUI from '@mui/material/Typography';

const hoverFade = keyframes`
  from {
    text-decoration: none;
  }
  to {
    text-decoration: underline;
  }
`;
export const TypographyLink = styled(TypographyMUI)`
    color: #757575;
    &:hover {
        cursor: pointer;
        animation-name: ${hoverFade};
        animation-duration: 0.2s;
        animation-fill-mode: forwards;
    }
`;

export const AccessButton = styled(ButtonMUI)`
    font-size: 12px;
    color: #ffffff;
    background-color: #5bb85d;
    text-transform: none;
    &:hover {
        background-color: #5bb85d;
    }
`;

interface TypographyProps {
  font?: string
}

export const Typography = styled(TypographyMUI)<TypographyProps>`
    font-size: ${(props) => props.font};
`;

interface GridProps {
  padding?: string
}

export const Grid = styled(GridMUI)<GridProps>`
    padding: ${(props) => props.padding};
`;

export const LoginContainer = styled(GridMUI)`
    padding: 15px;
    border-radius: 15px;
    background-color: #ffffff;
    max-width: 350px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    z-index: 1;
`;

export const FormContainer = styled(GridMUI)`
    padding: 15px;
    border-radius: 15px;
    background-color: #e0e0e0;
`;
