//colors.ts

const colors = {
    primary: 'rgb(151, 15, 15)',
    primary80: 'rgb(151, 15, 15, 0.8)',

    secondary: 'rgb(255, 225, 211)',

    darkGrey: 'rgb(2, 2, 2, 0.8)',
    red: 'rgb(220, 38, 38)',
    green: 'rgb(22, 163, 74)',
    black: 'rgb(0, 0, 0)',
    blackT: 'rgb(0, 0, 0, 0.5)',
    white: 'rgb(255, 255, 255)',
    white80: 'rgb(255, 255, 255, 0.2)'
  }

export default colors