import styled from 'styled-components';
import IconButtonMUI from '@mui/material/IconButton';

export const IconButton = styled(IconButtonMUI)`
    padding: 0px;
    margin-bottom: 15px;
    @media (max-width: 767px) {
        margin-bottom: 8px;
    }
`;
