import { useState } from 'react';

const useDialog = () => {
    const [open, setOpen] = useState(false);

    const handleOpenDialog = () => {
        setOpen(true);
    };

    const handleCancelar = () => {
        setOpen(false);
    };

    return {
        setOpen,
        handleOpenDialog,
        handleCancelar,
        open,
    };
};

export default useDialog;
