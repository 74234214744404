import { Raiting } from '@/components';
import { getIsTouchDevice } from '@/utils';
import { Grid, useMediaQuery } from '@mui/material';
import { Typography } from '../../styled';
import { Pregunta, SurveyStepsHandler } from '@/hooks/useSurvey';

interface props {
    step: Pregunta;
    handleSurveySteps: SurveyStepsHandler;
    iStep: number;
    finalStep: boolean;
    handleNext: () => void;
}

const RaitingStep = ({ step, handleSurveySteps, iStep, handleNext, finalStep }: props) => {
    const isTouchDevice = getIsTouchDevice();
    const xs = useMediaQuery('(min-width: 500px)');
    return (
        <>
            <Grid
                justifyContent={isTouchDevice && xs ? 'center' : 'flex-start'}
                padding={isTouchDevice ? '0 0 40px 0' : '0 0 10px 0'}
                container
            >
                <Typography
                    color={'#1b1c1d'}
                    font={isTouchDevice ? (xs ? '27px' : '20px') : undefined}
                    align={isTouchDevice ? 'center' : 'right'}
                    variant={isTouchDevice ? undefined : 'h6'}
                >
                    <strong>{step.cPregunta}</strong>
                </Typography>
            </Grid>
            <Raiting
                type={step.nTipo}
                // name="simple-controlled"
                raitingValue={Number(step.cValor)}
                setRaitingValue={(value) => {
                    handleSurveySteps(iStep, 'cValor', String(value));
                    if (!finalStep) {
                        handleNext();
                    }
                }}
            />
        </>
    );
};

export default RaitingStep;
