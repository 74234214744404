import styled, { keyframes } from 'styled-components';
import GridMUI from '@mui/material/Grid';
import TypographyMUI from '@mui/material/Typography';
import ErrorIconMUI from '@mui/icons-material/Error';

const show = keyframes`
	0%{
		transform:  scale(1);
	}

	15% {
		transform:  scale(.75);
	}

	30% {
		transform:  scale(1);
	}

	45% {
		transform:  scale(.85);
	}

	60% {
		transform:  scale(1);
	}

	75% {
		transform:  scale(.95);
	}

	100% {
		transform:  scale(1);
	}
`;

const showOpacity = keyframes`
	from{
		opacity:0;
	}

	to{
		opacity:1;
	}
`;

interface TypographyProps {
	padding?: string
	color?: string
	children?: JSX.Element[] | JSX.Element | string
}

export const Typography = styled(TypographyMUI)<TypographyProps>`
    padding: ${(props) => props.padding};
	color: ${(props) => props.color}
`;

export const DoneContainer = styled(GridMUI)`
    padding: 10px 0 0 0;
    animation:
        ${show} 0.6s,
        ${showOpacity} 0.6s;
`;

export const ErrorIcon = styled(ErrorIconMUI)`
    color: #e64a3b;
    font-size: 150px;
`;
