import { Route, createBrowserRouter, createRoutesFromElements } from "react-router-dom";
import { Root } from "@/pages/Root";
import SurveyView from "./views/survey";
import { ProtectedRoute } from "./pages/ProtectedRoute";
import { MainViewPage } from "@/pages";
import { ValidarOTP } from "./pages/ValidarOTP";
import { NotFoundPage } from "./pages/404";

// Separar bundle en pequeños archivos
// export const router = createBrowserRouter(
// 	createRoutesFromElements(
// 		<Route Component={Root}>
// 			<Route path={`/ValidarOTP/:otp`} lazy={() => import('@/pages/ValidarOTP')} />

// 			<Route lazy={() => import('@/pages/ProtectedRoute')}>
// 				<Route path='/' lazy={() => import('@/pages')} />

// 				<Route path='/encuestas/:encuestaId' lazy={() => import('@/pages/encuestas')} />
// 			</Route>

// 			<Route path="/encuestaToken/:encuestaToken" lazy={() => import('@/pages/encuestasToken')} />

// 			<Route path='*' lazy={() => import('@/pages/404')} />
// 		</Route>
// 	)
// );

export const router = createBrowserRouter(
	createRoutesFromElements(
		<Route Component={Root}>
			<Route path={`/ValidarOTP/:otp`} element={<ValidarOTP />} />

			<Route element={<ProtectedRoute />}>
				<Route path='/' element={<MainViewPage />} />

				<Route path='/encuestas/:encuestaId' element={<SurveyView />} />
			</Route>

			<Route path="/encuestaToken/:encuestaToken" element={<SurveyView validarPorToken />} />

			<Route path='*' element={<NotFoundPage />} />
		</Route>
	)
);
