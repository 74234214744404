import { Typography, useMediaQuery } from '@mui/material';
import { Grid } from '../TextField/styled';
import { IconButton } from './styled';
import { getIconsYesNoIcons, getIconsEmotionalIcons, getIconsGradeIcons } from './helpers';
import { getIsTouchDevice } from '@/utils';
import { useStoreState } from '@/hooks';

interface props {
	type: number
    raitingValue: number
    setRaitingValue: (value: number) => void
}

const EmojiRaiting = ({ raitingValue, setRaitingValue, type }: props) => {
    const { primaryColor } = useStoreState();
    const md = useMediaQuery('(min-width: 767px)');
    const sm = useMediaQuery('(min-width: 600px)');
    const isTouchDevice = getIsTouchDevice();
    const iconSize = md ? (isTouchDevice ? '120' : '80') : sm ? '70' : '60';

	const iconTypes = {
		2: getIconsYesNoIcons(iconSize, primaryColor),
		5: getIconsGradeIcons(iconSize, primaryColor)
	}

    const icons = iconTypes[type as keyof typeof iconTypes] || getIconsEmotionalIcons(iconSize, primaryColor);

    return (
        <>
            <Grid padding={sm ? undefined : '30px 0 0 0'} item container xs={12} alignItems="flex-start">
                {icons.map((item, i) => (
                    <Grid alignItems="center" container direction="column" item xs justifyContent="center" key={i}>
                        <IconButton onClick={() => setRaitingValue(item.value)}>
                            {raitingValue === item.value ? item.checkedIcon : item.icon}
                        </IconButton>
                        {sm && (
                            <Typography variant={md ? 'body1' : 'caption'} align="center">
                                {item.label}
                            </Typography>
                        )}
                    </Grid>
                ))}
            </Grid>
        </>
    );
};

export default EmojiRaiting;
