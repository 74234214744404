export interface actionType {
    type: string;
    payload?: unknown;
    initial_state?: Partial<appBarState>;
}

export interface UserInfo {
    nUsuario: number;
    cLogin: string;
    cNombre: string;
    cDeviceToken: string;
}

export interface appBarState {
    userInfo: UserInfo;
    oUser: UserInfo;
    primaryColor: string;
    icon: string;
    backgroundImg: string;
    cDescripcion: string;
    surveytoken: null | undefined | string;
    loading: boolean;
    back: string | false;
    bIsLogged: boolean;
    cSessionToken: string;
}

// Use the initialState as a default value
export const appBarReducer = (state: appBarState, action: actionType): appBarState => {
    switch (action.type) {
        case 'UPDATE_BACK': {
            const newItem = action.payload as appBarState['back'];
            return { ...state, back: newItem };
        }
        case 'UPDATE_USER_INFO': {
            const newItem = action.payload as appBarState['userInfo'];
            localStorage.setItem('userInfo', JSON.stringify(newItem));
            return { ...state, userInfo: newItem };
        }
        case 'UPDATE_COLOR': {
            const newItem = action.payload as appBarState['primaryColor'];
            localStorage.setItem('primaryColor', newItem);
            return { ...state, back: newItem };
        }
        case 'UPDATE_ASSETS': {
            const newItem = action.payload as {
                primaryColor: appBarState['primaryColor'];
                icon: appBarState['icon'];
                backgroundImg: appBarState['backgroundImg'];
                cDescripcion: appBarState['cDescripcion'];
            };
            localStorage.setItem('primaryColor', newItem.primaryColor);
            localStorage.setItem('icon', newItem.icon);
            localStorage.setItem('backgroundImg', newItem.backgroundImg);
            localStorage.setItem('cDescripcion', newItem.cDescripcion);
            return {
                ...state,
                primaryColor: newItem.primaryColor,
                icon: newItem.icon,
                backgroundImg: newItem.backgroundImg,
                cDescripcion: newItem.cDescripcion,
            };
        }
        case 'UPDATE_SURVEY_TOKEN': {
            const newItem = action.payload as string;
            if (newItem) {
                localStorage.setItem('surveytoken', newItem);
            } else {
                localStorage.removeItem('surveytoken');
            }
            return { ...state, surveytoken: newItem };
        }
        case 'UPDATE_USER': {
            localStorage.setItem('oUser', JSON.stringify(action.payload));
            return { ...state, oUser: action.payload as appBarState['oUser'] };
        }
        case 'UPDATE_IS_LOGGED': {
            localStorage.setItem('bIsLogged', action.payload as string);
            return { ...state, bIsLogged: action.payload as appBarState['bIsLogged'] };
        }
        case 'UPDATE_SESSION_TOKEN': {
            localStorage.setItem('SSO_CREDENTIALS', action.payload as string);
            return { ...state, cSessionToken: action.payload as appBarState['cSessionToken'] };
        }
        case 'RESET':
            return {
                ...state,
                ...action.initial_state,
            };
        default:
            // If this reducer doesn't recognize the action type, or doesn't
            // care about this specific action, return the existing state unchanged
            return state;
    }
}
