import styled from 'styled-components';
import GridMUI from '@mui/material/Grid';

interface GridProps {
	padding?: string
	bgcolor?: string
	margin?: string
}

export const Grid = styled(GridMUI)<GridProps>`
    padding: ${(props) => props.padding};
    background-color: ${(props) => props.bgcolor};
    margin: ${(props) => props.margin};
`;

interface MainLayoutProps {
	bordercolor?: string
	istouchdevice?: 'true' | 'false' | string
}

export const MainLayout = styled(GridMUI)<MainLayoutProps>`
    padding: 5%;
    border-right: 35px solid ${(props) => props.bordercolor};
    border-left: 35px solid ${(props) => props.bordercolor};
    background-color: #ffffff !important;
    z-index: 1;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

    ${(props) => props.istouchdevice === 'true' ? `
        border-radius: 0px;
        height: 100%;
    ` : `
        min-height: 400px;
        border-radius: 4px;
    `}
`;
