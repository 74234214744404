import { DoneContainer, CheckIcon, Typography } from './styled';
import { Button } from '@mui/material';
import { useTimer } from 'hooks';
import { SurveyLayout } from '@/components';

interface props {
    handleReset: () => void;
}

const SuccessMessageComponent = ({ handleReset }: props) => {
    const { progress } = useTimer(10, handleReset);

    return (
        <SurveyLayout>
            <DoneContainer direction="column" container justifyContent="center" alignItems="center">
                <CheckIcon />
                <Typography padding="15px 0 15px 0" variant="subtitle1" align="center">
                    ¡Muchas gracias por contestar nuestra encuesta!
                </Typography>
                <Typography padding="0 0 0px 0" color="#757575" variant="subtitle2" align="center">
                    Queremos mejorar para darle un mejor servicio.
                </Typography>

                <Typography
                    variant="subtitle2"
                    padding="0px 0 10px 0"
                    color="#757575"
                >{`Redireccionando en ${progress}`}</Typography>
                <Button onClick={handleReset} color="primary" variant="contained">
                    <Typography color="#fff">CONTESTAR DE NUEVO</Typography>
                </Button>
            </DoneContainer>
        </SurveyLayout>
    );
};

export default SuccessMessageComponent;
